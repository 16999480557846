






import { defineComponent } from '@vue/composition-api'
import { Empty } from 'element-ui'
export default defineComponent({
    components: {
        [Empty.name]: Empty
    },
    setup(props, { root }) {
        return {}
    }
})
